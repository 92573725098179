import React, { useState, useEffect, useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import { Link } from "gatsby"

import "./styles.scss"
import { SOFTWARE_DEVELOPMENT_SERVICES } from "../software-development/const"

const CardsCarousel = ({ screenWidth }) => {
  const sliderRef = useRef(null)
  const [settings, setSettings] = useState({})

  useEffect(() => {
    document.addEventListener("keydown", handleKeyClicking)
    return () => {
      document.removeEventListener("keydown", handleKeyClicking)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    let settingsData = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "cards-carousel",
    }

    if (screenWidth > 601 && screenWidth < 901) {
      settingsData.slidesToShow = 2
      settingsData.slidesToScroll = 2
    }
    if (screenWidth >= 901) {
      settingsData.slidesToShow = 3
      settingsData.slidesToScroll = 3
    }

    setSettings(settingsData)
  }, [screenWidth])

  const handleKeyClicking = e => {
    if (e.keyCode === 39) {
      sliderRef.current.slickNext()
    }
    if (e.keyCode === 37) {
      sliderRef.current.slickPrev()
    }
  }

  return (
    <div className="cards-carousel-container custom-buttons">
      <Slider ref={sliderRef} {...settings}>
        {SOFTWARE_DEVELOPMENT_SERVICES.map(item => (
          <div key={item.id}>
            <Link to={item.link} data-attr-title="Learn more">
              <h3>{item.title}</h3>
            </Link>
            <p>{item.text}</p>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default CardsCarousel
