// extracted by mini-css-extract-plugin
export var billetShadow = "software-development-module--billet-shadow--1e46e";
export var buttonContainer = "software-development-module--buttonContainer--8d090";
export var colorScheme__background__grayPale = "software-development-module--colorScheme__background__gray-pale--62f18";
export var commonGlossy__article = "software-development-module--commonGlossy__article--d0003";
export var commonGlossy__article__caseStudies = "software-development-module--commonGlossy__article__case-studies--25b4a";
export var commonGlossy__article__headline = "software-development-module--commonGlossy__article__headline--175a2";
export var commonGlossy__article__section = "software-development-module--commonGlossy__article__section--53f61";
export var common__list__techLogosRounded = "software-development-module--common__list__techLogos-rounded--15eef";
export var glossy = "software-development-module--glossy--f2750";
export var icomoon = "software-development-module--icomoon--c85f6";
export var indent0 = "software-development-module--indent0--0c4aa";
export var indent1o2 = "software-development-module--indent1o2--fb9e0";
export var indent1o25 = "software-development-module--indent1o25--a599a";
export var indent1o6 = "software-development-module--indent1o6--e1171";
export var indent2 = "software-development-module--indent2--0f5ef";
export var indent2o4 = "software-development-module--indent2o4--722f0";
export var indent3o2 = "software-development-module--indent3o2--04624";
export var indentBot0 = "software-development-module--indentBot0--5711b";
export var indentBot1o2 = "software-development-module--indentBot1o2--3ae71";
export var indentBot1o25 = "software-development-module--indentBot1o25--009bc";
export var indentBot1o6 = "software-development-module--indentBot1o6--94642";
export var indentBot2 = "software-development-module--indentBot2--2c40b";
export var indentBot2o4 = "software-development-module--indentBot2o4--48f13";
export var indentBot3o2 = "software-development-module--indentBot3o2--81602";
export var indentTop0 = "software-development-module--indentTop0--92d91";
export var indentTop1o2 = "software-development-module--indentTop1o2--5f838";
export var indentTop1o25 = "software-development-module--indentTop1o25--c6e74";
export var indentTop1o6 = "software-development-module--indentTop1o6--3997c";
export var indentTop2 = "software-development-module--indentTop2--824e2";
export var indentTop2o4 = "software-development-module--indentTop2o4--172df";
export var indentTop3o2 = "software-development-module--indentTop3o2--1c5cf";
export var lowercase = "software-development-module--lowercase--253ad";
export var onDesktop = "software-development-module--onDesktop--b43de";
export var onMobile = "software-development-module--onMobile--f862e";
export var softwareDevelopmentBlock = "software-development-module--softwareDevelopmentBlock--ff85b";
export var softwareDevelopmentBlock__accompany__leadQuote = "software-development-module--softwareDevelopmentBlock__accompany__leadQuote--2694f";
export var softwareDevelopmentBlock__caseStudies = "software-development-module--softwareDevelopmentBlock__caseStudies--f49ea";
export var softwareDevelopmentBlock__customersList = "software-development-module--softwareDevelopmentBlock__customersList--5a6c2";
export var softwareDevelopmentBlock__dev = "software-development-module--softwareDevelopmentBlock__dev--5a5ef";
export var softwareDevelopmentBlock__dev__carouselPlaceholder = "software-development-module--softwareDevelopmentBlock__dev__carouselPlaceholder--0a124";
export var softwareDevelopmentBlock__facts = "software-development-module--softwareDevelopmentBlock__facts--a881e";
export var softwareDevelopmentBlock__goldenShine = "software-development-module--softwareDevelopmentBlock__goldenShine--42974";
export var softwareDevelopmentBlock__headline = "software-development-module--softwareDevelopmentBlock__headline--64b25";
export var softwareDevelopmentBlock__leadingBusiness = "software-development-module--softwareDevelopmentBlock__leadingBusiness--104da";
export var softwareDevelopmentBlock__leadingBusiness__capture = "software-development-module--softwareDevelopmentBlock__leadingBusiness__capture--3bb90";
export var softwareDevelopmentBlock__leadingBusiness__summary = "software-development-module--softwareDevelopmentBlock__leadingBusiness__summary--db700";
export var softwareDevelopmentBlock__leadingBusiness__tech = "software-development-module--softwareDevelopmentBlock__leadingBusiness__tech--19f0f";
export var softwareDevelopmentBlock__leadingBusiness__techLogos = "software-development-module--softwareDevelopmentBlock__leadingBusiness__techLogos--63567";
export var softwareDevelopmentBlock__mmmdash = "software-development-module--softwareDevelopmentBlock__mmmdash--1ce50";
export var softwareDevelopmentBlock__numbers = "software-development-module--softwareDevelopmentBlock__numbers--90e97";
export var transition = "software-development-module--transition--25efe";
export var transitionBackground = "software-development-module--transition-background--16763";
export var transitionReverse = "software-development-module--transition-reverse--dcd1e";
export var uppercase = "software-development-module--uppercase--9c5a5";