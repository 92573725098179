export const SOFTWARE_DEVELOPMENT_SERVICES = [
  {
    id: "1",
    title: "Web Development",
    link: "/web-development/",
    text:
      "We have extensive expertise in delivering complex projects and ensuring that our clients receive beautiful, custom web applications  after working with our professional teams.",
  },
  {
    id: "2",
    title: "MVP Development",
    link: "/mvp-development/",
    text:
      "Have a business idea? Start with a Minimum Viable Product. It allows you to test the concept’s viability and save you from the hassle associated with significant investments in creating a full-fledged digital product.",
  },
  {
    id: "3",
    title: "Mobile Development",
    link: "/mobile-development/",
    text:
      " Make your business closer to your clients through a mobile app (Android and iOS) and upgrade clients’ engagement as a reward.",
  },
  {
    id: "4",
    title: "Dashboard Development",
    link: "/dashboard/",
    text:
      "A custom solution to help our clients to serve their clients better, be more efficient and achieve results faster for less.",
  },
  {
    id: "5",
    title: "Custom Software Development",
    link: "/custom-software-development/",
    text:
      "Get craft software for your business! We create unique products that solve a specific problem you have.",
  },
]

export const CUSTOMERS = [
  { name: "Call Criteria", image: "logo-CallCriteria" },
  { name: "Vendorstan", image: "logo-Vendorstan" },
  { name: "Kamsa", image: "logo-Kamsa" },
  { name: "The Spoke", image: "logo-TheSpoke" },
  { name: "Dope 360", image: "logo-Dope360" },
]

export const SOFTWARE__DEVELOPMENT_SERVICES = [
  {
    id: 1,
    title: "Full-cycle software development",
    text:
      "We can plan, design, and develop your product as a solo vendor. We take care of all aspects of software development, including Product Design and Architecture, UI/UX design, Web App development, Quality Assurance, Project Management and Project Support.",
  },
  {
    id: 2,
    title: "World-class Product Design expertise",
    text:
      "Are you wondering if you should create a new design or buy a template? Many of our customers have this same dilemma when they decide to build a webpage. This option is great for testing your ideas.",
  },
  {
    id: 3,
    title: "Cloud Infrastructure deployment and support",
    text:
      "We can create or develop cloud infrastructure for business. Want to remove your business from the cloud? We’ll take care of it. Want to optimize your finances in DevOps? We will find the cheapest solution without losing the quality of your business!",
  },
  {
    id: 4,
    title: "Expertise in Data Analytics and Custom Dashboard Development",
    text:
      "We have extensive knowledge in developing high-load dashboards with modern infographics. Our teammates worked in world-famous companies like HP, Pearson, Adidas, Boss, Calvin Klein, and s.Oliver.",
  },
  {
    id: 5,
    title: "Mobile Development",
    text:
      "Android or iOS? You don’t have to choose. We make solutions for all operating systems.",
  },
]

export const TECHNOLOGIES = [
  { name: "React", icon: "react.svg" },
  { name: "Angular", icon: "angular.svg" },
  { name: "JavaScript", icon: "javascript.svg" },
  { name: "Webpack", icon: "webpack.svg" },
  { name: "Django", icon: "django.svg" },
  { name: "Storybook", icon: "storybook.svg" },
  { name: "HTML5", icon: "html5.svg" },
  { name: "Python Flask", icon: "flask-icon.svg" },
  { name: "Ruby on Rails", icon: "ruby-on-rails.svg" },
  { name: "Laravel", icon: "laravel.svg" },
  { name: "NodeJS", icon: "nodejs.svg" },
  { name: ".NET", icon: "dotnet.svg" },
  { name: "C++", icon: "c%2B%2B.svg" },
]
